<template>
  <div class="text-format">
    <h2>{{ textContent.title }}</h2>
    <pre class="content" ref="content">{{ textContent.content | ellipsis }}</pre>
    <!--<div class="content" ref="content">{{ textContent.content}}</div>-->
    <span
        @click="openDialog(textContent.title,textContent.content,textContent.sendTime)"
        v-if="showMore||(textContent.content && textContent.content.length > 500)"
        style="cursor: pointer;align-self: flex-end">
      {{ $t('ReadMore') }}
    </span>
    <div class="msgTime">{{ textContent.sendTime }}</div>
  </div>
</template>

<script>

export default {
  name: '',
  props:{
    textContent: Object
  },
  components: {},
  data() {
    return {
      contentHeight:'',
      lineHeight:'',
      showMore:false,
    };
  },
  filters: {
    ellipsis(value) {
      if (!value) return ''
      // console.log('value.length ->',value.length)
      if (value.length > 500) {
        return value.slice(0, 500) + '...'
      }
      return value
    }
  },
  created() {},
  mounted() {
    this.$nextTick(()=>{
      this.contentHeight = this.$refs.content.offsetHeight
      console.log('this.contentHeight ->',this.contentHeight)
      // console.log('this.$refs.content.offsetWidth ->',this.$refs.content.offsetWidth)
      this.showMore = this.contentHeight >= 140;
      console.log('this.showMore ->',this.showMore)
    })
  },
  methods: {
    openDialog(title,content,time){
      this.$emit('readDialog',title,content,time)
    }
  }
};
</script>

<style lang="scss" scoped>
.text-format{
  width: 100%;
  min-height: 150px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  
  h2{
    margin-bottom: 20px;
    width: 90%;
    letter-spacing:1px;
    word-wrap: break-word;
    display: inline-block;
  }
  .content {
    float: left;
    width: 90%;
    word-wrap: break-word;
    display: inline-block;
    line-height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    overflow: hidden;
  }
  .msgTime {
    align-self: flex-end;
  }
}
pre{
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>